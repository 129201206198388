class GoogleAnalytics {
  constructor(measurementId, debugEnabled = false) {
    this.measurementId = measurementId;
    this.debugEnabled = debugEnabled;

    var script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${this.measurementId}`;
    script.async = true;
    document.head.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    window.gtag = function(){window.dataLayer.push(arguments);};
    window.gtag('js', new Date());
  }

  debug(...messages) {
    if (this.debugEnabled) {
      console.log('DEBUG ANALYTICS', ...messages);
    }
  }

  config(authenticatedUser) {
    setTimeout(() => {
      storeUTMParameters();
      window.gtag('config', this.measurementId, {
        'page_title': getPageTitle(document.title),
        'user_id': getUserId(authenticatedUser),
      });
      this.debug('config', this.measurementId, authenticatedUser);
      this.pageView();
    }, 0);
  }  
  
  pageView() {
    /* Sleep so elm can update the document title after rendering the page */
    setTimeout(() => {
      const utmParams = getStoredUTMParameters();
      var title = getPageTitle(document.title);

      var params = {
        'page_title': title,
        ...utmParams
      };

      gtag('event', 'page_view', params);

      this.debug('page_view', title, params);
    }, 50);
  }

  themeSwitch(useDarkTheme) {
    gtag('event', 'switch_theme', {
      'theme': useDarkTheme ? 'dark' : 'light',
    });
    this.debug('switch_theme', useDarkTheme);
  }
  
  search(query) {
    gtag('event', 'search', {
      'search_term': query
    });  
    this.debug('search', query);
  }
  
  login(authenticatedUser) {
    setTimeout(() => {
      gtag('event', 'login', {
      'method': 'Email',
      });
      gtag('set', {'user_id': getUserId(authenticatedUser)});
      gtag('set', {'user_properties': {'role': getUserRole(authenticatedUser)}}); 
      this.debug('login', authenticatedUser); 
    }, 100);
  }
  
  logout() {
    gtag('event', 'logout');
    gtag('set', {'user_id': null});
    gtag('set', {'user_properties': {'role': 'Guest'}});
    this.debug('logout');
  }
  
  signup(authenticatedUser) {
    setTimeout(() => {
      const utmParams = getStoredUTMParameters();

      var params = {
        'method': 'Email',
        ...utmParams
      };

      gtag('event', 'sign_up', params);
      gtag('set', {'user_id': getUserId(authenticatedUser)});
      gtag('set', {'user_properties': {'role': getUserRole(authenticatedUser), 'is_admin': getIsAdmin(authenticatedUser)}});
      this.debug('sign_up', authenticatedUser, params);
    }, 100);
  }

  signUpStep(params) {
    gtag('event', 'sign_up_step', {
      'step': params.step,
      'direction': params.direction
    });
    this.debug('sign_up_step', params);
  }
  
  event(eventName) {
    gtag('event', eventName);
    this.debug('event', eventName);
  }
    
  hashtag(eventName, tagName) {
    gtag('event', eventName, {
      'hashtag': tagName
    });
    this.debug('hashtag', eventName, tagName);
  }
  
  itemEvent(eventName, itemType) {
    gtag('event', eventName, {
      'item_type': toTitleCase(itemType)
    });
    this.debug('item_event', eventName, itemType);
  }

  filterEvent(eventName, filter) {
    gtag('event', eventName, {
      'filter': filter
    });
    this.debug('filter', eventName, filter);
  }

  trackTab(eventName, tabName) {
    gtag('event', eventName, {
      'tab': tabName
    });
    this.debug('tab', eventName, tabName);
  }

  notificationClick(notificationAction) {
    gtag('event', 'notification_click', {
      'notification_action': notificationAction
    });
    this.debug('notification_click', notificationAction);
  }

}

export default GoogleAnalytics;


function getPageTitle(title) {
  var dashIndex = title.indexOf(' - ');
  return dashIndex >= 0 ? title.substring(dashIndex + 3) : title;
}

function getUserRole(authenticatedUser) {
  return authenticatedUser ? authenticatedUser.role : 'Guest';
}

function getIsAdmin(authenticatedUser) {
  return authenticatedUser ? authenticatedUser.isAdmin : false;
}

function getUserId(authenticatedUser) {
  return authenticatedUser ? authenticatedUser.id : null;
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

function getUTMParametersFromLocation() {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = {};

  const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

  utmKeys.forEach(key => {
    if (urlParams.has(key)) {
      utmParams[key] = urlParams.get(key);
    }
  });

  return utmParams;
}

function storeUTMParameters() {
  const utmParams = getUTMParametersFromLocation();

  Object.keys(utmParams).forEach(key => {
    if (utmParams[key]) {
      sessionStorage.setItem(key, utmParams[key]);
    }
  });
}

function getStoredUTMParameters() {
  const utmParams = {
    utm_source: sessionStorage.getItem('utm_source') || '',
    utm_medium: sessionStorage.getItem('utm_medium') || '',
    utm_campaign: sessionStorage.getItem('utm_campaign') || '',
    utm_term: sessionStorage.getItem('utm_term') || '',
    utm_content: sessionStorage.getItem('utm_content') || ''
  };
  return utmParams;  
}